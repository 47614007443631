// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-jsx": () => import("/opt/build/repo/src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-page-jsx": () => import("/opt/build/repo/src/templates/ProjectsPage.jsx" /* webpackChunkName: "component---src-templates-projects-page-jsx" */),
  "component---src-templates-homepage-jsx": () => import("/opt/build/repo/src/templates/Homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-info-page-jsx": () => import("/opt/build/repo/src/templates/InfoPage.jsx" /* webpackChunkName: "component---src-templates-info-page-jsx" */),
  "component---src-templates-contact-jsx": () => import("/opt/build/repo/src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-private-jsx": () => import("/opt/build/repo/src/templates/Private.jsx" /* webpackChunkName: "component---src-templates-private-jsx" */),
  "component---src-templates-legal-jsx": () => import("/opt/build/repo/src/templates/Legal.jsx" /* webpackChunkName: "component---src-templates-legal-jsx" */)
}

