import React from 'react'
import PropTypes from 'prop-types'
import withProjectsLinkData from '../../hocs/withProjectsLinkData'
import AniLink from '../AniLink/AniLink'
import styles from './HeaderProjectsLink.module.scss'

const colors = {
  black: '',
  brown: 'md:text-gold-100',
  gold: 'md:text-brown-200',
  goldWithBg: 'md:text-brown-200',
  goldOnDark: 'md:text-gold-100',
  goldOnDarkWithBg: 'md:text-gold-100',
  gray: 'md:text-red-100',
  grayWithBg: 'md:text-red-100',
  grayDark: 'md:text-brown-200',
  homeHero: 'md:text-gray-200',
}

const HeaderProjectsLink = ({ title, path, theme }) => {
  return (
    <AniLink
      to={`/${path}`}
      className={[
        'relative',
        'mr-3',
        'xl:mr-6',
        'font-sans-display',
        'text-base',
        'md:text-lg',
        'xl:text-xl',
        'text-red-100',
        colors[theme],
        styles.link,
      ].join(' ')}
    >
      <span>{title}</span>
    </AniLink>
  )
}

HeaderProjectsLink.propTypes = {
  lang: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
}

export default withProjectsLinkData(HeaderProjectsLink)
