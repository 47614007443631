import React from 'react'
import PropTypes from 'prop-types'

const CloseMainNav = ({ onClick, color }) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className='focus:outline-none pb-3 border-b border-transparent'
    >
      <svg
        fill='none'
        height='33'
        viewBox='0 0 33 33'
        width='33'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='m1 32 31-31m0 31-31-31' stroke={color} strokeMiterlimit='10' />
      </svg>
    </button>
  )
}

CloseMainNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
}

export default CloseMainNav
