/**
 * action types
 */

export const SET_HEADER_THEME = 'SET_HEADER_THEME'

/**
 * action creators
 */

/**
 * @param {('brown'|'gold'|'goldWithBg'|'goldOnDark'|'goldOnDarkWithBg'|'gray'|'grayWithBg'|'grayDark'|'homeHero')} theme
 */
export function setHeaderTheme(theme) {
  return {
    type: SET_HEADER_THEME,
    theme,
  }
}
