import React from 'react'
import styles from './LoadingIcon.module.scss'

const LoadingIcon = () => {
  return (
    <svg
      width='100px'
      height='100px'
      enableBackground='new 0 0 100 100'
      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
      className={styles.svg}
    >
      <g fill='#FAF7F6'>
        <path d='m50 100c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zm0-99.3c-27.2 0-49.3 22.1-49.3 49.3s22.1 49.3 49.3 49.3 49.3-22.1 49.3-49.3-22.1-49.3-49.3-49.3z' />
        <path
          d='m50 22.9h.7v54.2h-.7z'
          transform='matrix(.905 -.4255 .4255 .905 -16.4948 26.1521)'
        />
        <path d='m15.5 54.4h.1c1.5 3.5 3.4 5.2 5.5 5.2 1.1 0 1.9-.3 2.6-1s1-1.4 1-2.3c0-1.6-1.1-3.1-3.3-4.5-.9-.6-1.8-1.2-2.7-1.8s-1.7-1.4-2.4-2.2c-.7-.9-1-1.9-1-2.9 0-1.5.6-2.8 1.9-3.7 1.2-1 2.6-1.5 4.1-1.5s3.2.4 5.1 1.2v4h-.1c-1.5-2.9-3.2-4.4-5.2-4.4-1 0-1.8.3-2.4.9-.7.6-1 1.3-1 2.1s.3 1.6 1 2.3c.6.7 1.4 1.3 2.3 1.9s1.9 1.2 2.8 1.9c.9.6 1.7 1.4 2.4 2.3s1 1.9 1 3c0 1.6-.6 2.9-1.8 3.9s-2.8 1.6-4.7 1.6-3.7-.4-5.2-1.2z' />
        <path d='m83.3 53.9c0-1.2-.2-2.1-.5-2.7-.3-.5-1-.9-1.9-1.2h7.1v.1c-.7.3-1.2.7-1.5 1.1-.3.5-.4 1.4-.4 2.7v5.4c-2.9.7-5.3 1.1-7.2 1.1-3 0-5.6-1-7.8-2.9-2.2-2-3.3-4.4-3.3-7.4 0-2.1.6-4 1.8-5.6s2.7-2.9 4.4-3.7 3.5-1.2 5.4-1.2c1 0 2.1.1 3.3.3s2.2.5 2.8.7v3.9h-.1c-1.6-2.8-3.7-4.2-6.3-4.2-2.2 0-4 .9-5.6 2.7s-2.3 4.1-2.3 6.9.8 5.1 2.3 6.9c1.6 1.8 3.5 2.7 5.9 2.7 1.3 0 2.5-.3 3.8-.9v-4.7z' />
      </g>
    </svg>
  )
}

export default LoadingIcon
