/**
 * action types
 */

export const SLIDE_HAS_DESCRIPTION = 'SLIDE_HAS_DESCRIPTION'

/**
 * action creators
 */

/**
 * @param {Boolean} hasDescription
 */
export function slideHasDescription(hasDescription) {
  return {
    type: SLIDE_HAS_DESCRIPTION,
    hasDescription,
  }
}
