import React, { Component } from 'react' // eslint-disable-line
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Portal extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.el = document.createElement('div')
    }
  }

  componentDidMount() {
    const root = document.querySelector('#___gatsby').querySelector('div')
    root.appendChild(this.el)
  }

  componentWillUnmount() {
    const root = document.querySelector('#___gatsby').querySelector('div')
    root.removeChild(this.el)
  }

  render() {
    if (typeof window !== 'undefined') {
      return ReactDOM.createPortal(this.props.children, this.el)
    } else {
      return null
    }
  }
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Portal
