import React from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.scss'
import HeaderBrand from '../HeaderBrand/HeaderBrand'
import { connect } from 'react-redux'
import { setMainNavVisibility } from '../../actions/mainNavVisibility'
import withMainHeaderData from '../../hocs/withMainHeaderData'
import MainNavIcon from '../MainNavIcon/MainNavIcon'
import HeaderProjectsLink from '../HeaderProjectsLink/HeaderProjectsLink'
import { Tablet } from '../MediaQueries/MediaQueries'

const Header = ({
  showNav,
  headerTheme,
  slideHasDescription,
  lang,
  homepagePath,
}) => {
  return (
    <>
      <div
        className={[
          'fixed',
          'top-0',
          'right-0',
          'left-0',
          'flex',
          'items-center',
          'px-6',
          'bg-gray-100',
          'md:bg-transparent',
          styles.container,
          slideHasDescription ? styles.slideHasDescription : '',
          headerTheme === 'grayWithBg' ? styles.hasGrayBg : '',
          headerTheme === 'goldWithBg' ? styles.goldWithBg : '',
          headerTheme === 'goldOnDarkWithBg' ? styles.goldOnDarkWithBg : '',
        ].join(' ')}
      >
        <HeaderBrand
          theme={headerTheme}
          homepagePath={homepagePath}
          lgForceRed
        />
        <div className='ml-auto text-sm flex'>
          <Tablet>
            <HeaderProjectsLink lang={lang} theme={headerTheme} />
          </Tablet>
          <button
            type='button'
            onClick={showNav}
            className={[
              'focus:outline-none',
              'focus:opacity-50',
              styles.button,
            ].join(' ')}
          >
            <MainNavIcon theme={headerTheme} />
          </button>
        </div>
      </div>
      <div className={[styles.spacer, 'bg-gray-100'].join(' ')} />
    </>
  )
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  showNav: PropTypes.func.isRequired,
  headerTheme: PropTypes.string.isRequired,
  slideHasDescription: PropTypes.bool.isRequired,
  homepagePath: PropTypes.string.isRequired,
}

const mapStateToProps = ({ headerTheme, slideHasDescription }) => {
  return { headerTheme, slideHasDescription }
}

const mapDispatchToProps = dispatch => {
  return {
    showNav: () => dispatch(setMainNavVisibility(true)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMainHeaderData(Header))
