import { SLIDE_HAS_DESCRIPTION } from '../actions/slideHasDescription'

export function slideHasDescription(state = false, action) {
  switch (action.type) {
    case SLIDE_HAS_DESCRIPTION:
      return action.hasDescription
    default:
      return state
  }
}
