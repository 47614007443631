import { SET_HEADER_THEME } from '../actions/headerTheme'

export function headerTheme(state = 'black', action) {
  switch (action.type) {
    case SET_HEADER_THEME:
      return action.theme
    default:
      return state
  }
}
