/**
 * action types
 */

export const SET_SHARE_MODAL_VISIBILITY = 'SET_SHARE_MODAL_VISIBILITY'

/**
 * action creators
 */

/**
 * @param {Boolean} visible
 */
export function setShareModalVisibility(visible) {
  return {
    type: SET_SHARE_MODAL_VISIBILITY,
    visible,
  }
}
