import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

/**
 * @param {Array} nodes
 * @param {String} lang
 */
function getCurrLangNode(nodes, currLang) {
  return nodes.find(({ lang }) => lang === currLang)
}

/**
 * @param {Array} nodes
 * @param {String} lang
 */
function createNavItems(nodes, lang) {
  const { data } = getCurrLangNode(nodes, lang)
  const { items } = data
  return items.map(({ itemText, item }, i) => {
    const { document } = item
    const id = `${document.id}${i}`
    const text = itemText || document.data.title.text
    const path = document.fields.pagePath
    return { id, text, path }
  })
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => {
    return (
      <StaticQuery
        query={graphql`
          query MainNavQuery {
            allNavs: allPrismicMainMenu {
              nodes {
                data {
                  items {
                    itemText: text
                    item {
                      document {
                        ... on PrismicProjectsPage {
                          id
                          data {
                            title {
                              text
                            }
                          }
                          fields {
                            pagePath
                          }
                        }
                        ... on PrismicInfoPage {
                          id
                          data {
                            title {
                              text
                            }
                          }
                          fields {
                            pagePath
                          }
                        }
                        ... on PrismicContact {
                          id
                          data {
                            title {
                              text
                            }
                          }
                          fields {
                            pagePath
                          }
                        }
                        ... on PrismicHomepage {
                          id
                          data {
                            title {
                              text
                            }
                          }
                          fields {
                            pagePath
                          }
                        }
                      }
                    }
                  }
                }
                lang
              }
            }
            homepages: allPrismicHomepage {
              nodes {
                lang
                fields {
                  pagePath
                }
              }
            }
            legalPages: allPrismicLegal {
              nodes {
                lang
                data {
                  title {
                    text
                  }
                }
                fields {
                  pagePath
                }
              }
            }
          }
        `}
        render={data => {
          const { lang } = props
          const items = createNavItems(data.allNavs.nodes, lang)
          const currHomepage = getCurrLangNode(data.homepages.nodes, lang)
          const { fields } = currHomepage
          const { pagePath } = fields
          const currLegalPage = getCurrLangNode(data.legalPages.nodes, lang)
          const legalTitle = currLegalPage.data.title.text
          const legalPath = currLegalPage.fields.pagePath
          return (
            <WrappedComponent
              items={items}
              homepagePath={pagePath}
              legalTitle={legalTitle}
              legalPath={legalPath}
              {...props}
            />
          )
        }}
      />
    )
  }

  hocComponent.propTypes = {
    lang: PropTypes.string.isRequired,
  }

  return hocComponent
}
