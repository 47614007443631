import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

/**
 * @param {Array} nodes
 * @param {String} lang
 */
function getCurrLangNode(nodes, currLang) {
  return nodes.find(({ lang }) => lang === currLang)
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => {
    return (
      <StaticQuery
        query={graphql`
          query MainHeaderQuery {
            homepages: allPrismicHomepage {
              nodes {
                lang
                fields {
                  pagePath
                }
              }
            }
          }
        `}
        render={data => {
          const { lang } = props
          const currHomepage = getCurrLangNode(data.homepages.nodes, lang)
          const { fields } = currHomepage
          const { pagePath } = fields
          return <WrappedComponent {...props} homepagePath={pagePath} />
        }}
      />
    )
  }

  hocComponent.propTypes = {
    lang: PropTypes.string.isRequired,
  }

  return hocComponent
}
