import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import fitty from 'fitty'
import styles from './MainNav.module.scss'
import withMainNavData from '../../hocs/withMainNavData'
import CloseMainNav from '../CloseMainNav/CloseMainNav'
import HeaderBrand from '../HeaderBrand/HeaderBrand'
import { setMainNavVisibility } from '../../actions/mainNavVisibility'
import AniLink from '../AniLink/AniLink'

const colors = {
  text: {
    black: 'md:text-red-100',
    brown: 'md:text-brown-200',
    gold: 'md:text-gold-100',
    goldWithBg: 'md:text-gold-100',
    goldOnDark: 'md:text-brown-200',
    goldOnDarkWithBg: 'md:text-brown-200',
    gray: 'md:text-red-100',
    grayWithBg: 'md:text-red-100',
    grayDark: 'md:text-red-100',
    homeHero: 'md:text-red-100',
  },
  background: {
    black: 'md:bg-gray-200',
    brown: 'md:bg-gold-100',
    gold: 'md:bg-brown-300',
    goldWithBg: 'md:bg-brown-300',
    goldOnDark: 'md:bg-gold-100',
    goldOnDarkWithBg: 'md:bg-gold-100',
    gray: 'md:bg-gray-200',
    grayWithBg: 'md:bg-gray-100',
    grayDark: 'md:bg-gray-200',
    homeHero: 'md:bg-gray-200',
  },
  icons: {
    black: '#A04646',
    brown: '#4A2F23',
    gold: '#C9B480',
    goldWithBg: '#C9B480',
    goldOnDark: '#4A2F23',
    goldOnDarkWithBg: '#4A2F23',
    gray: '#A04646',
    grayWithBg: '#A04646',
    grayDark: '#A04646',
    homeHero: '#A04646',
  },
}

const classes = {
  container: ['fixed', 'inset-0', 'bg-gray-100', styles.container].join(' '),
  inner: ['absolute', 'inset-0', 'flex', 'flex-col', 'justify-center'].join(
    ' '
  ),
  brand: [
    'absolute',
    'top-0',
    'left-0',
    'ml-6',
    'flex',
    'items-center',
    styles.brand,
  ].join(' '),
  list: [
    'mt-auto',
    'xs:mt-0',
    'uppercase',
    'text-center',
    'text-red-100',
    //
  ].join(' '),
  translations: [
    'mt-auto',
    'xs:absolute',
    'xs:right-0',
    'xs:bottom-0',
    'xs:mr-6',
    'xs:mb-6',
    'flex',
    'justify-center',
    'font-sans-display',
    'leading-none',
    'text-sm',
    'text-red-100',
  ].join(' '),
  copyright: [
    'mt-3',
    'xs:absolute',
    'xs:left-0',
    'xs:bottom-0',
    'xs:ml-6',
    'mb-6',
    'font-sans-display',
    'leading-none',
    'text-sm',
    'text-red-100',
    'text-center',
  ].join(' '),
}

class MainNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listFontSize: 24,
    }
    this.setFontSize = this.setFontSize.bind(this)
  }

  componentDidMount() {
    this.fittedLink.addEventListener('fit', this.setFontSize)

    setTimeout(() => {
      fitty.fitAll()
    }, 300)
  }

  componentWillUnmount() {
    this.fittedLink.removeEventListener('fit', this.setFontSize)
  }

  setFontSize(e) {
    const { detail } = e
    const { newValue } = detail
    this.setState({ listFontSize: newValue })
  }

  componentDidUpdate(prevProps) {
    const { mainNavIsVisible } = this.props
    if (prevProps.mainNavIsVisible !== mainNavIsVisible && mainNavIsVisible) {
      setTimeout(() => {
        fitty(this.fittedLink, { maxSize: 140 }) // 115
      }, 100)
    }
  }

  render() {
    const {
      items,
      translations,
      mainNavIsVisible,
      hideNav,
      headerTheme,
      homepagePath,
      legalTitle,
      legalPath,
    } = this.props
    const { listFontSize } = this.state
    return (
      <div
        className={`${classes.container} ${
          mainNavIsVisible ? styles.visible : ''
        } ${colors.background[headerTheme]}`}
      >
        <div className={`${classes.inner} ${styles.inner}`}>
          <div className={classes.brand}>
            <HeaderBrand
              color={colors.icons[headerTheme]}
              homepagePath={homepagePath}
            />
          </div>
          <ul
            className={`${classes.list} ${styles.list} ${colors.text[headerTheme]}`}
          >
            {items.map(({ id, text, path }, i) => {
              let formattedPath = path

              if (path[0] !== '/') formattedPath = `/${path}`

              const link = (
                <AniLink to={formattedPath} onClick={hideNav} className='block'>
                  <div
                    className={
                      i % 2
                        ? 'inline-block font-serif-display tracking-tighter'
                        : 'inline-block font-sans-display font-light tracking-tight'
                    }
                  >
                    <span>{text}</span>
                  </div>
                </AniLink>
              )
              const fittedLink = (
                <div className={`${styles.fittedLink} mr-auto ml-auto`}>
                  <div ref={el => (this.fittedLink = el)}>{link}</div>
                </div>
              )
              const resizedLink = (
                <div style={{ fontSize: `${listFontSize}px` }}>{link}</div>
              )
              const content = i === 0 ? fittedLink : resizedLink
              return (
                <li key={id} style={{ height: `${listFontSize}px` }}>
                  {content}
                </li>
              )
            })}
          </ul>
          <ul className={`${classes.translations} ${colors.text[headerTheme]}`}>
            {translations.map(({ nativeName, path }, i) => {
              let formattedPath = path

              if (path[0] !== '/') formattedPath = `/${path}`

              return (
                <li key={i} className={i > 0 ? 'ml-3' : ''}>
                  <AniLink to={formattedPath} onClick={hideNav}>
                    <span>{nativeName}</span>
                  </AniLink>
                </li>
              )
            })}
          </ul>
          <div className={`${classes.copyright} ${colors.text[headerTheme]}`}>
            Sandra Gross © 2019
            <AniLink to={`/${legalPath}`} className='ml-3'>
              {legalTitle}
            </AniLink>
          </div>
          <div className='absolute top-0 right-0 mt-4 mr-4 md:mt-6 md:mr-6'>
            <CloseMainNav color={colors.icons[headerTheme]} onClick={hideNav} />
          </div>
        </div>
      </div>
    )
  }
}

MainNav.propTypes = {
  items: PropTypes.array.isRequired,
  translations: PropTypes.array.isRequired,
  mainNavIsVisible: PropTypes.bool.isRequired,
  hideNav: PropTypes.func.isRequired,
  headerTheme: PropTypes.string,
  homepagePath: PropTypes.string.isRequired,
  legalTitle: PropTypes.string.isRequired,
  legalPath: PropTypes.string.isRequired,
}

const mapStateToProps = ({ mainNavIsVisible, headerTheme }) => {
  return { mainNavIsVisible, headerTheme }
}

const mapDispatchToProps = dispatch => {
  return {
    hideNav: () => dispatch(setMainNavVisibility(false)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMainNavData(MainNav))
