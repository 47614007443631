import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from 'gatsby'
import { connect } from 'react-redux'
import { setPageOverlayVisibility } from '../../actions/pageOverlayVisibility'
import { setMainNavVisibility } from '../../actions/mainNavVisibility'
import Portal from '../Portal/Portal'
import fireEventOnElement from '../../utils/fireEventOnElement'

class AniLink extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      fireEventOnElement(this.gatsbyLink, 'mouseover')
    }, 1000)
  }

  handleClick(e) {
    e.preventDefault()
    this.handleNavigation()
  }

  handleNavigation() {
    const {
      to,
      state,
      setPageOverlayVisibility,
      hideMainNav,
      currentLocation,
    } = this.props

    if (!currentLocation) return

    const { pathname } = currentLocation

    if (
      pathname.replace('/', '') === to.replace('/', '') ||
      to.replace('/', '') === pathname.replace('/', '')
    ) {
      hideMainNav()
      navigate(to, { state })
      return
    }

    if (state && state.disableAnimation) {
      navigate(to, { state })
    } else {
      hideMainNav()
      setPageOverlayVisibility(true)
      setTimeout(() => {
        navigate(to, { state })
      }, 300)
    }
  }

  render() {
    const { to, children, className, id } = this.props
    const gatsbyLinkDest = to === '/' ? to : `/${to}`
    return (
      <>
        <a href={to} onClick={this.handleClick} className={className} id={id}>
          {children}
        </a>
        <Portal>
          <Link
            to={gatsbyLinkDest}
            ref={el => (this.gatsbyLink = el)}
            className='hidden'
          >
            {to}
          </Link>
        </Portal>
      </>
    )
  }
}

AniLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  state: PropTypes.object,
  setPageOverlayVisibility: PropTypes.func,
  currentLocation: PropTypes.object,
}

const mapStateToProps = ({ currentLocation }) => {
  return { currentLocation }
}

const mapDispatchToProps = dispatch => {
  return {
    setPageOverlayVisibility: visibility =>
      dispatch(setPageOverlayVisibility(visibility)),
    hideMainNav: () => dispatch(setMainNavVisibility(false)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AniLink)
