import { combineReducers } from 'redux'
import { localizeReducer } from 'react-localize-redux'
import { currentLocation } from './currentLocation'
import { headerTheme } from './headerTheme'
import { mainNavIsVisible } from './mainNavIsVisible'
import { pageOverlayIsVisible } from './pageOverlayIsVisible'
import { shareModalIsVisible } from './shareModalIsVisible'
import { slideHasDescription } from './slideHasDescription'

const reducers = combineReducers({
  currentLocation,
  headerTheme,
  localize: localizeReducer,
  mainNavIsVisible,
  pageOverlayIsVisible,
  shareModalIsVisible,
  slideHasDescription,
})

export default reducers
