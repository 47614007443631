import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

/**
 * @param {Array} nodes
 * @param {String} currLang
 */
function getCurrLangNode(nodes, currLang) {
  return nodes.find(({ lang }) => lang === currLang)
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => {
    return (
      <StaticQuery
        query={graphql`
          query ProjectsLinkQuery {
            allPages: allPrismicProjectsPage {
              nodes {
                lang
                data {
                  title {
                    text
                  }
                }
                fields {
                  pagePath
                }
              }
            }
          }
        `}
        render={data => {
          const { lang } = props
          const node = getCurrLangNode(data.allPages.nodes, lang)

          if (!node) return null

          const title = node.data.title.text
          const path = node.fields.pagePath

          return <WrappedComponent title={title} path={path} {...props} />
        }}
      />
    )
  }

  hocComponent.propTypes = {
    lang: PropTypes.string.isRequired,
  }

  return hocComponent
}
