/**
 * @param {Element} element
 * @param {String} eventName
 */
function fireEventOnElement(element, eventName) {
  if (element === null) return

  if (element.fireEvent) {
    element.fireEvent('on' + eventName)
  } else {
    const evObj = document.createEvent('Events')
    evObj.initEvent(eventName, true, false)
    element.dispatchEvent(evObj)
  }
}

export default fireEventOnElement
