import React from 'react'
import PropTypes from 'prop-types'
import styles from './MainNavIcon.module.scss'

const colors = {
  black: '#281d18',
  brown: '#C9B480',
  gold: '#4A2F23',
  goldWithBg: '#4A2F23',
  goldOnDark: '#C9B480',
  goldOnDarkWithBg: '#C9B480',
  gray: '#A04646',
  grayWithBg: '#A04646',
  grayDark: '#4A2F23',
  homeHero: '#D0D1D2',
}

const MainNavIcon = ({ theme }) => {
  return (
    <svg
      fill='none'
      height='12'
      viewBox='0 0 39 12'
      width='39'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g className={styles.g} stroke={colors[theme]} strokeWidth='2'>
        <path d='m0 6h39' />
        <path d='m0 11h39' />
        <path d='m0 1h39' />
      </g>
    </svg>
  )
}

MainNavIcon.propTypes = {
  theme: PropTypes.string,
}

MainNavIcon.defaultProps = {
  theme: 'black',
}

export default MainNavIcon
