import { SET_CURRENT_LOCATION } from '../actions/currentLocation'

export function currentLocation(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return action.location
    default:
      return state
  }
}
