import React from 'react'
import PropTypes from 'prop-types'
import styles from './HeaderBrand.module.scss'
import AniLink from '../AniLink/AniLink'

const colors = {
  black: '#281d18',
  brown: '#4A2F23',
  gold: '#4A2F23',
  goldWithBg: '#4A2F23',
  goldOnDark: '#C9B480',
  goldOnDarkWithBg: '#C9B480',
  gray: '#A04646',
  grayWithBg: '#A04646',
  grayDark: '#4A2F23',
  homeHero: '#4A2F23',
}

const HeaderBrand = ({ color, theme, homepagePath, lgForceRed }) => {
  let formattedPath = homepagePath

  if (homepagePath[0] !== '/') formattedPath = `/${homepagePath}`

  return (
    <div
      className={[
        styles.container,
        theme === 'homeHero' ? styles.hidden : '',
        lgForceRed ? styles.lgForceRed : '',
      ].join(' ')}
    >
      <AniLink to={formattedPath} id='HeaderBrandLink'>
        <svg
          fill='none'
          height='24'
          viewBox='0 0 170 24'
          width='170'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g fill={color ? color : colors[theme]}>
            <path d='m115.761 16.4156v5.5746c-2.633.7809-5.121 1.1622-7.463 1.1622-3.106 0-5.72-1.0169-7.827-3.0325-2.1061-2.0156-3.1412-4.5941-3.1412-7.7355 0-1.6524.345-3.15961 1.0169-4.55781 1.2529-2.57852 3.3593-4.43069 6.3193-5.57467 1.362-.50844 2.633-.76266 3.831-.76266 1.199 0 2.452.12711 3.723.36317 1.289.23606 2.251.47212 2.923.7445v4.06751h-.127c-1.616-2.99616-3.795-4.50332-6.555-4.50332-2.252 0-4.176.94425-5.793 2.85089-1.616 1.90665-2.433 4.33989-2.433 7.28159s.817 5.3568 2.47 7.2089c1.652 1.8522 3.686 2.7783 6.083 2.7964.871 0 1.725-.0908 2.597-.2723.853-.1816 1.489-.3814 1.888-.563v-5.048c0-1.2711-.163-2.1972-.508-2.7601s-1.017-.9806-2.016-1.2348v-.1271h6.937v.1271c-.745.2905-1.253.6719-1.526 1.1803-.272.5084-.399 1.4527-.399 2.8146z' />
            <path d='m143.235 20.7009c-1.525 1.5798-3.359 2.3788-5.502 2.3788s-3.977-.7808-5.502-2.3606-2.27-3.4865-2.27-5.7381c0-2.2517.763-4.1402 2.306-5.68365 1.544-1.54348 3.36-2.30614 5.466-2.30614s3.94.76266 5.484 2.30614c1.543 1.54345 2.306 3.43195 2.306 5.66545-.018 2.2517-.763 4.1583-2.288 5.7381zm-5.52 1.7977c1.108 0 2.052-.3813 2.814-1.1621 1.435-1.4527 2.161-3.541 2.161-6.3011 0-2.07-.435-3.8132-1.343-5.24777-.908-1.43453-2.125-2.16087-3.65-2.16087-1.526 0-2.742.72634-3.614 2.16087-.89 1.43457-1.344 3.19587-1.344 5.24777 0 2.742.709 4.8484 2.143 6.3011.781.7626 1.725 1.1621 2.833 1.1621z' />
            <path d='m147.792 17.8315h.127c1.344 3.0688 2.996 4.6122 4.976 4.6122.853 0 1.597-.2723 2.197-.8171.599-.5448.908-1.1803.908-1.8885 0-.7263-.273-1.3437-.817-1.8703-.545-.5266-1.217-.9624-2.016-1.3438-.781-.3813-1.58-.7626-2.361-1.1803-.799-.4176-1.471-.9805-2.015-1.6887-.545-.7082-.817-1.5435-.817-2.5059 0-1.28924.472-2.30611 1.434-3.05061.944-.76266 2.07-1.12583 3.378-1.12583 1.307 0 2.669.29053 4.067.87161v3.64983h-.145c-1.126-2.6148-2.542-3.90405-4.249-3.90405-.745 0-1.38.23606-1.925.72634s-.817 1.08951-.817 1.81581c0 .7264.272 1.3256.817 1.8159.563.4903 1.253.9079 2.052 1.2711.799.345 1.58.7263 2.379 1.1258s1.47.9624 2.033 1.6706.836 1.6343.836 2.7964c0 1.1622-.491 2.1609-1.453 2.9962s-2.27 1.2529-3.886 1.2529-3.178-.4176-4.685-1.2348v-3.9948z' />
            <path d='m159.977 17.8315h.127c1.343 3.0688 2.996 4.6122 4.975 4.6122.854 0 1.598-.2723 2.197-.8171.6-.5448.908-1.1803.908-1.8885 0-.7263-.272-1.3437-.817-1.8703s-1.217-.9624-2.016-1.3438c-.78-.3813-1.579-.7626-2.36-1.1803-.799-.4176-1.471-.9805-2.016-1.6887-.544-.7082-.817-1.5435-.817-2.5059 0-1.28924.472-2.30611 1.435-3.05061.944-.76266 2.07-1.12583 3.377-1.12583 1.308 0 2.669.29053 4.068.87161v3.64983h-.164c-1.126-2.6148-2.542-3.90405-4.249-3.90405-.744 0-1.38.23606-1.925.72634-.544.49028-.817 1.08951-.817 1.81581 0 .7264.273 1.3256.817 1.8159.563.4903 1.253.9079 2.052 1.2711.799.345 1.58.7263 2.379 1.1258s1.471.9624 2.034 1.6706.835 1.6343.835 2.7964c0 1.1622-.49 2.1609-1.453 2.9962-.962.8353-2.269 1.2529-3.886 1.2529-1.616 0-3.177-.4176-4.684-1.2348z' />
            <path d='m124.113 6.99081v11.93019c0 1.2711.164 2.1245.509 2.5966.327.454 1.035.8172 2.088 1.0896v.1271h-7.155v-.1271c.908-.2906 1.489-.6538 1.78-1.1077.291-.454.418-1.3074.418-2.5785v-6.6642c0-1.3256-.146-2.1972-.418-2.65116-.291-.4358-.999-.81713-2.125-1.14398v-.10896c1.308-.05447 2.906-.50843 4.831-1.36189zm4.939.49029c.327.32685.509.7445.509 1.21662s-.164.88977-.509 1.21662c-.326.32686-.744.49026-1.216.49026s-.89-.1634-1.217-.50842c-.327-.32685-.508-.7445-.508-1.19846 0-.47212.163-.88977.508-1.21662.327-.32686.745-.50844 1.217-.50844.472.01815.871.18158 1.216.50844z' />
            <path d='m0 16.9426h.12711c1.32557 3.7043 3.23222 5.5565 5.71994 5.5565 1.10767 0 2.07007-.3632 2.90537-1.1077.83529-.7445 1.25298-1.6343 1.25298-2.6875 0-1.0531-.34506-1.9974-1.03508-2.8145-.69003-.799-1.50716-1.489-2.48772-2.0701-.9624-.5811-1.94297-1.1803-2.90537-1.7614-.98056-.5992-1.79769-1.3437-2.48772-2.21532-.690022-.88977-1.0350344-1.88849-1.0350344-2.99616 0-1.57979.6173904-2.86905 1.8340144-3.84961 1.23478-.99872 2.61483-1.489 4.14014-1.489 1.52532 0 3.26854.39949 5.19337 1.18031v4.14014h-.1271c-1.30745-3.08695-3.08698-4.64858-5.33864-4.64858-1.03504 0-1.90665.32685-2.61483.98056-.70819.65371-1.0532 1.47084-1.0532 2.4514s.34501 1.83402 1.0532 2.61483c.70818.78082 1.54347 1.43453 2.50588 1.99743.98056.5629 1.96112 1.144 2.94168 1.7614s1.81581 1.38 2.50591 2.3061c.69.9261 1.035 1.9611 1.035 3.1233 0 1.7069-.6355 3.1051-1.9248 4.1765-1.28926 1.0713-2.86905 1.6161-4.7757 1.5979-1.90664 0-3.70434-.3995-5.4294-1.1985z' />
            <path d='m41.4939 18.9032v-6.8094c0-2.37881-1.1803-3.57727-3.5409-3.59543-1.1622 0-2.4878.4358-3.9768 1.30741v9.18822c0 1.2348.1453 2.0701.4177 2.5059.2905.4358.8716.799 1.7795 1.0895v.1271h-6.7731v-.1271c.9079-.2905 1.489-.6537 1.7795-1.0895.2906-.4358.4177-1.2711.4177-2.5059v-6.41c0-1.1621-.0545-2.0156-.1998-2.524-.1271-.50844-.345-.88976-.6537-1.12583-.3087-.23606-.8898-.45396-1.7069-.67186v-.10895c1.6161-.10895 3.2141-.50844 4.7939-1.16215h.1271v2.37877c1.9793-1.59795 3.8133-2.37877 5.5383-2.37877 1.2893 0 2.3425.41765 3.1596 1.23478.8171.83529 1.2348 2.12451 1.2348 3.88591v6.7732c.0182 1.2529.1453 2.1063.4176 2.5785.2724.4539.8717.8353 1.7614 1.1258v.1271h-6.7731v-.1271c.9079-.2905 1.489-.6537 1.7614-1.1258.2905-.454.4358-1.3074.4358-2.5604z' />
            <path d='m61.6313 0v17.2506c0 1.7614.1453 2.8691.4176 3.3412.2724.4721.9988.8534 2.1427 1.144v.1271c-1.725.1634-3.3593.5629-4.9028 1.1984h-.1271v-2.869c-.581.7445-1.3437 1.3982-2.3061 1.9793s-2.0338.8716-3.2322.8897c-1.7433-.0181-3.2322-.69-4.467-2.0337s-1.8522-3.087-1.8522-5.2115c0-2.7601.8353-4.921 2.5059-6.50077 1.6706-1.57979 3.7225-2.36061 6.1557-2.34245.908 0 1.9793.12711 3.2141.36317v-2.25166c.0181-1.32557-.1453-2.19718-.4903-2.61483-.3268-.41764-1.0713-.76266-2.2153-.99872v-.12711c1.9429-.16342 3.6317-.617388 5.0299-1.34373zm-10.4775 9.69666c-.9261 1.30744-1.3982 2.95984-1.3982 5.01174 0 2.0338.5084 3.6317 1.5253 4.7757s2.3969 1.7251 4.1402 1.7251c1.1803-.0182 2.4332-.454 3.7406-1.3438v-10.53191c-1.2892-1.05319-2.7056-1.56163-4.2672-1.56163-1.5617-.03632-2.8146.61739-3.7407 1.9248z' />
            <path d='m70.0732 6.99081v11.93019c0 1.2711.1634 2.1245.5085 2.5966.3268.454 1.035.8172 2.0882 1.0896v.1271h-7.1545v-.1271c.9079-.2906 1.489-.6538 1.7796-1.1077.2905-.454.4176-1.3074.4176-2.5785v-6.6642c0-1.3256-.1453-2.1972-.4176-2.65116-.2906-.4358-.9988-.81713-2.1246-1.14398v-.10896c1.3074-.05447 2.9054-.50843 4.8302-1.36189zm4.8483.49029c.3269.32685.5085.7445.5085 1.21662s-.1634.88977-.5085 1.21662c-.3268.32686-.7445.49026-1.2166.49026s-.8897-.1634-1.2166-.50842c-.3268-.34501-.5084-.7445-.5084-1.19846 0-.47212.1634-.88977.5084-1.21662.345-.32686.7445-.50844 1.2166-.50844.4721.01815.8716.18158 1.2166.50844z' />
            <path d='m87.1425 21.627c-.8171-.2905-1.2166-1.1258-1.2166-2.4696v-7.9716c0-1.3437-.3995-2.37873-1.2167-3.10508-.8171-.72634-1.9429-1.08951-3.3774-1.08951-1.4346 0-2.633.78082-2.633.78082l.1815.19974c1.1077-.4358 2.4878-.50844 3.5773.67187.7445.79897 1.1077 2.12456 1.0895 3.68616v1.8885c-3.1414.1271-5.3931.6356-6.7368 1.5435-1.3438.9079-2.0156 2.0156-2.0156 3.3048 0 1.2893.3995 2.2699 1.1984 2.978.799.6901 1.7614 1.0351 2.8509 1.0351 1.5617-.0182 3.1415-.7082 4.7394-2.1064.0182.6537.1998 1.144.5629 1.4708.345.345.7808.5085 1.2711.5085s1.1259-.109 1.8885-.3269c.7627-.2179 1.2893-.3995 1.5616-.5084v-.1453c-.69-.0726-1.2711-.1816-1.725-.345zm-3.5591-.8353c-.9261.5629-1.9248.8353-2.9417.8353-1.035 0-1.8521-.2542-2.4695-.7627-.6174-.5084-.9806-1.5071-.9261-2.5785.0545-.9079.4358-1.725 1.2892-2.4151.8535-.6718 1.9975-1.0168 3.4138-1.0168.5448.0181 1.0896.0726 1.6343.1634z' />
            <path d='m15.6525 10.0414s2.3425-3.06874 5.2479-3.06874c1.4345 0 2.5422.36317 3.3593 1.07135.8171.72634 1.2166 1.74322 1.2166 3.06879v7.899c0 1.3437.3995 2.1608 1.2166 2.4514.454.1634 1.0169.2905 1.7251.345v.1089c-.2905.1271-.799.2906-1.5616.5085-.7627.2179-1.3801.3268-1.8885.3268-.5085 0-.9261-.1634-1.2711-.5084-.345-.3269-.5448-.8171-.5629-1.4527-1.598 1.3801-3.1778 2.0882-4.7213 2.0882-1.1076 0-2.0519-.345-2.8509-1.035-.7989-.69-1.1984-1.6706-1.1984-2.9417s.6718-2.3606 2.0156-3.2685c1.3437-.9079 3.5772-1.4164 6.7186-1.5253v-1.8341c0-2.88716-.8534-4.32168-3.2685-4.32168-2.1064 0-3.9404 2.19718-3.9404 2.19718zm1.1985 8.3167c0 1.0713.3087 1.8521.9079 2.3424.5993.4903 1.4164.7627 2.4514.7627 1.0169 0 1.9975-.2724 2.9236-.8172v-5.7199c-.5448-.0908-1.0714-.1453-1.6162-.1634-1.4163 0-2.5421.3268-3.3956.9987-.8353.6719-1.2711 1.5435-1.2711 2.5967z' />
          </g>
        </svg>
      </AniLink>
    </div>
  )
}

HeaderBrand.propTypes = {
  theme: PropTypes.string,
  color: PropTypes.string,
  homepagePath: PropTypes.string.isRequired,
  lgForceRed: PropTypes.bool,
}

HeaderBrand.defaultProps = {
  theme: 'gray',
}

export default HeaderBrand
