import React from 'react'
import Header from '../components/Header/Header'
import MainNav from '../components/MainNav/MainNav'
import PageOverlay from '../components/PageOverlay/PageOverlay'
import '../styles/base.css'
import '../styles/webfonts.css'
import '../styles/styles.css'
import '../styles/pswp.css'

const TransitionLayout = ({ children, ...props }) => {
  const { pageContext } = props
  const { translations, lang } = pageContext

  if (!translations || !lang) return null

  return (
    <>
      <Header lang={lang} />
      <MainNav key={lang} translations={translations} lang={lang} />
      {children}
      <PageOverlay />
    </>
  )
}

export default TransitionLayout
