import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './PageOverlay.module.scss'
import LoadingIcon from '../LoadingIcon/LoadingIcon'

const PageOverlay = ({ pageOverlayIsVisible }) => {
  return (
    <div
      className={[
        'flex',
        'items-center',
        'justify-center',
        'fixed',
        'inset-0',
        'bg-gray-200',
        styles.overlay,
        pageOverlayIsVisible ? styles.visible : '',
      ].join(' ')}
    >
      <div
        className={[
          styles.icon,
          pageOverlayIsVisible ? styles.visible : '',
        ].join(' ')}
      >
        <LoadingIcon />
      </div>
    </div>
  )
}

PageOverlay.propTypes = {
  pageOverlayIsVisible: PropTypes.bool,
}

const mapStateToProps = ({ pageOverlayIsVisible }) => {
  return { pageOverlayIsVisible }
}

export default connect(mapStateToProps)(PageOverlay)
