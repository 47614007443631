import { SET_SHARE_MODAL_VISIBILITY } from '../actions/shareModalVisibility'

export function shareModalIsVisible(state = false, action) {
  switch (action.type) {
    case SET_SHARE_MODAL_VISIBILITY:
      return action.visible
    default:
      return state
  }
}
