/**
 * action types
 */

export const SET_CURRENT_LOCATION = `SET_CURRENT_LOCATION`

/**
 * action creators
 */

/**
 * @param {Object} location
 */
export function setCurrentLocation(location) {
  return {
    type: SET_CURRENT_LOCATION,
    location,
  }
}
