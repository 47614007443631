/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'url-search-params-polyfill'
import 'custom-event-polyfill'
// import objectFitImages from 'object-fit-images'
import React from 'react'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import createStore from './src/store/createStore'
import MainLayout from './src/layouts/MainLayout'

// objectFitImages()

export const wrapRootElement = ({ element }) => {
  const store = createStore()
  const ConnectedRootElement = (
    <Provider store={store}>
      <LocalizeProvider store={store}>{element}</LocalizeProvider>
    </Provider>
  )

  return ConnectedRootElement
}

const transitionDelay = 350

export const wrapPageElement = ({ element, props }) => {
  return <MainLayout {...props}>{element}</MainLayout>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
