import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768 })
  return isTablet ? children : null
}

export const Mobile = ({ children }) => {
  // const isMobile = useMediaQuery({ maxWidth: 767 })
  // return isMobile ? children : null
  return <div className='md:hidden'>{children}</div>
}
